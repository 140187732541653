<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	const { menu } = defineProps({
		menu: {
			type: Array,
			required: true,
		},
	});

	// Stores
	import { useWindowStore } from '@/stores/window';

	// Variables
	const { $bus } = useNuxtApp();
	const { innerWidth } = storeToRefs(useWindowStore());
	const fullMenuOpen = ref(false);
	const searchOpen = ref(false);
	const itemOpen = ref(false);

	// Methods
	const openItem = (item, key) => {
		if (item.subitems && item.subitems.length) itemOpen.value = item.url;
	};

	const closeItem = (item, key) => {
		itemOpen.value = false;
		const rootItem = document.getElementById(`item-${key}`);
		if (rootItem) {
			rootItem.focus();
		}
	};

	const openMenu = () => {
		$bus.emit('scroll-lock', { menu: true });
		fullMenuOpen.value = true;
	};

	const closeMenu = () => {
		$bus.emit('scroll-unlock');
		fullMenuOpen.value = false;
	};

	const openSearch = () => {
		$bus.emit('scroll-lock', { search: true });

		searchOpen.value = true;
		setTimeout(() => {
			$bus.emit('open-search');
		}, 32);
	};

	const closeSearch = () => {
		$bus.emit('scroll-unlock');
		searchOpen.value = false;
	};

	const toggle = (type) => {
		switch (type) {
			case 'menu':
				if (searchOpen.value) closeSearch();
				if (fullMenuOpen.value) closeMenu();
				else openMenu();
				break;
			case 'search':
				if (fullMenuOpen.value) closeMenu();
				if (searchOpen.value) closeSearch();
				else openSearch();
				break;
			default:
				console.error('No type provided');
				break;
		}
	};

	const rootClasses = computed(() => {
		return {
			'full-menu-open': fullMenuOpen.value,
			'search-open': searchOpen.value,
		};
	});

	useNuxtApp().hook('page:finish', () => {
		closeMenu();
		closeSearch();
	});
</script>

<template>
	<nav class="global-menu" :class="rootClasses">
		<ul v-if="menu.length > 0" class="priority-menu">
			<template v-for="(item, key) in menu" :key="key">
				<li
					v-if="item.priority"
					class="parent-item"
					:class="itemOpen == item.url ? 'open' : 'closed'"
					:id="`item-${key}`"
				>
					<MessLink :href="item.url" @mouseover="openItem(item, key)" @mouseleave="closeItem(item, key)">
						<span class="parent-link">{{ item.label }}</span>
					</MessLink>
					<button
						class="sr-only sr-only-focusable skip-link open-sub-nav"
						v-if="item.subitems && item.subitems.length && itemOpen !== item.url"
						@click="openItem(item)"
					>
						Open {{ item.label }} dropdown menu
					</button>
					<!-- <button
						class="sr-only sr-only-focusable skip-link"
						v-if="item.subitems && item.subitems.length && itemOpen == item.url"
						@click="closeItem(item)"
					>
						Close {{ item.label }} dropdown menu
					</button> -->
					<div
						class="sub-menu-wrapper"
						v-if="item.subitems && item.subitems.length"
						v-hidden="itemOpen !== item.url"
						@mouseover="openItem(item, key)"
						@mouseleave="closeItem(item, key)"
					>
						<ul class="sub-menu" role="menu" aria-label="submenu">
							<li class="close-button">
								<button
									class="sr-only sr-only-focusable skip-link"
									v-if="item.subitems && item.subitems.length && itemOpen == item.url"
									@click="closeItem(item)"
								>
									Close {{ item.label }} dropdown menu
								</button>
							</li>
							<li v-for="subItem in item.subitems">
								<MessLink :href="subItem.url" :aria-label="subItem?.aria_label" class="caps" role="menuitem">
									<span>{{ subItem.label }}</span>
								</MessLink>
							</li>
						</ul>
					</div>
				</li>
			</template>
		</ul>
		<div class="actions">
			<button
				@click="toggle('menu')"
				class="menu desktop-menu-toggle"
				:class="fullMenuOpen ? 'open' : 'close'"
				:title="`${fullMenuOpen ? 'Close' : 'Open'} the full menu`"
			>
				<span class="hamburger">
					<span class="top"></span>
					<span class="bottom"></span>
				</span>
				<Transition name="label-fade" mode="out-in">
					<span v-if="fullMenuOpen" class="label">close</span>
					<span v-else class="label">more</span>
				</Transition>
			</button>
			<button
				@click="toggle('menu')"
				class="mobile-menu-toggle"
				:class="fullMenuOpen ? 'open' : 'close'"
				:title="`${fullMenuOpen ? 'Close' : 'Open'} the full menu`"
			>
				<span class="hamburger">
					<span class="a"></span>
					<span class="b"></span>
					<span class="c"></span>
					<span class="d"></span>
				</span>
			</button>

			<button
				@click="toggle('search')"
				class="search"
				:class="searchOpen ? 'open' : 'close'"
				title="Open the search modal"
			>
				<ProjectSvg type="search" />
				<span class="sr-only">Search</span>
			</button>
			<ProjectModal :opened="fullMenuOpen" @close="closeMenu" :classes="'full-menu-modal'">
				<GlobalMobileMenu v-if="innerWidth <= 768" />
				<GlobalFullMenu v-else />
			</ProjectModal>
			<ProjectModal :opened="searchOpen" @close="closeSearch" :classes="'search-modal'">
				<GlobalAlgolia />
			</ProjectModal>
		</div>
	</nav>
</template>

<style lang="scss">
	.global-menu {
		display: flex;
		align-items: center;
		gap: 1.5rem;
		height: 100%;

		.priority-menu {
			display: flex;
			justify-content: space-between;
			list-style: none;
			margin: 0;
			padding: 0;
			color: black;
			gap: 1rem;
			height: 100%;

			li {
				position: relative;
				height: 100%;

				a {
					// height: 100%;
					position: relative;
					span {
						position: relative;
						color: var(--black);
						text-align: center;
						font-size: 12px;
						font-style: normal;
						font-weight: 800;
						line-height: 123.1%; /* 14.772px */
						letter-spacing: 1.38px;
						text-transform: uppercase;
						background-image: linear-gradient(transparent, transparent);
						background-position: 0 100%;
						background-repeat: no-repeat;
						background-size: 0 0;
						box-decoration-break: clone;
						-webkit-box-decoration-break: clone;
						transition: 0.25s ease-in-out;
					}

					&:hover,
					&.mess-active-link,
					&.mess-current-active-link {
						span {
							transition: 250ms ease-in-out;
							color: var(--red);
							background-image: linear-gradient(currentColor, currentColor);
							background-size: 100% 1px;
						}
					}
				}
				&.close-button {
					margin-bottom: -2rem;
				}
				&.parent-item {
					position: relative;
					height: 100%;
					// display: block;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.parent-link {
						box-decoration-break: clone;
						-webkit-box-decoration-break: clone;
					}

					.sub-menu-wrapper {
						--menu-transition: 350ms ease-in-out;
						opacity: 0;
						transform: scaleY(0);
						transform-origin: top;
						position: absolute;
						transition: var(--menu-transition);
						width: fit-content;
						top: 100%;

						.sub-menu {
							display: flex;
							transition: var(--menu-transition);
							flex-direction: column;
							justify-content: flex-end;
							align-items: flex-start;
							gap: 2rem;
							width: 200%;
							padding: 1.5rem 1.5rem 2rem 2rem;
							height: 0;
							background-color: var(--red);
							a,
							span {
								position: relative;
								color: var(--white);
								transition: 200ms ease-in-out;
								opacity: 0;
							}
						}
					}

					&.open {
						.sub-menu-wrapper {
							opacity: 1;
							transform: scaleY(1);
							transform-origin: top;

							.sub-menu {
								height: 100%;
								span,
								a {
									opacity: 1;
								}
							}
						}
					}
				}
			}
			.skip-link {
				color: rgb(255, 255, 255);
				text-transform: uppercase;
				font-size: 12px;
				letter-spacing: 1px;
				font-weight: 700;
				border-width: initial;
				border-style: none;
				border-color: initial;
				border-image: initial;
				background: rgb(9, 52, 118);
				padding: 8px;
				&.open-sub-nav {
					position: absolute;
					top: 50px;
					width: 200%;
				}
			}
		}

		.actions {
			display: flex;
			gap: 0.75rem;

			button {
				padding: 1em 1.5em;
				border: 1px solid;
				border-radius: 50px;
				height: 40px;

				&.menu {
					position: relative;
					display: flex;
					align-items: center;
					padding-left: 3em;
					transition: 250ms ease-in-out;
					background: white;
					z-index: 2;

					.top,
					.bottom {
						--offset: 3px;
						--rotate: 0;
						--translateY: calc(-50% + var(--offset));
						--translateX: 0;
						position: absolute;
						top: 50%;
						left: 1.25em;

						transform-origin: center;
						transform: translateY(var(--translateY));

						height: 2px;
						background: currentColor;
						width: 1em;

						transition: 250ms ease-in-out;
					}

					.top {
						--rotate: -90deg;
						--offset: -3px;
					}

					.label {
						color: var(--black, #000);
						font-size: 12px;
						font-style: normal;
						font-weight: 800;
						line-height: 123.1%; /* 14.772px */
						letter-spacing: 1.38px;
						text-transform: uppercase;
						margin-bottom: -2px;
						width: 5ch;
					}

					span {
						transition: 250ms ease-in-out;
					}

					&:hover,
					&.open {
						.top,
						.bottom {
							width: 0.8em;
							height: 1.5px;
							transform: translateY(0) rotateZ(var(--rotate));
						}
						&:hover {
							span {
								color: var(--red);
							}
						}
					}

					&::before {
						content: '';
						position: absolute;
						top: -75%;
						right: 105%;
						height: 300%;
						width: 200vw;
						background: var(--gray-100);
						z-index: -1;
						clip-path: inset(0 0 0 100%);
						transition: 350ms ease-in-out;
					}

					&.open {
						transform: translateX(3.25rem);

						&::before {
							clip-path: inset(0 0 0 0);
						}

						.top {
							--rotate: -225deg;
						}
						.bottom {
							--rotate: 45deg;
						}
					}
				}

				&.search {
					width: 40px;
					padding: 0;
					border-radius: 50%;
					position: relative;
					transition: 250ms ease-in-out;

					.mess-svg {
						@include absolute-center;
						width: 35%;
						height: 35%;
						transition: 250ms ease-in-out;
					}

					&:hover,
					&.open {
						color: var(--red);
						.mess-svg {
							transform: scale(1.2);
						}
					}
				}
			}

			.mobile-menu-toggle {
				display: none;
			}
		}

		@media (max-width: 1350px) {
			.priority-menu {
				display: none;
			}
		}

		@media (max-width: $mobile) {
			.actions {
				button {
					border: 0;

					&.mobile-menu-toggle {
						display: block;
						width: 4rem;
						padding: 0;
						.hamburger {
							width: 2rem;
							aspect-ratio: 1;
							margin: 1rem;
							position: relative;
							font-size: 13px;
							color: var(--Neutrals-Eclipse, #213343);

							span {
								width: 2rem;
								height: 2px;
								display: block;
								background: currentColor;
								position: absolute;
								left: 0;
								transition: var(--transition);
								transition-property: top, transform;
								transform-origin: center;

								&.more {
									position: absolute;
									left: calc(-5ch - 1em);
									top: 50%;
									background: transparent;
									width: 5ch;
									height: auto;
									transform: translateY(-50%);
									padding-right: 1em;

									font-size: 13px;
									font-style: normal;
									font-weight: 600;
									line-height: normal;
									letter-spacing: 1.3px;
									text-transform: uppercase;
								}

								&.a {
									top: 20%;
									transform: translateY(-50%);
								}

								&.b,
								&.c {
									top: 50%;
									transform: translateY(-50%);
								}

								&.d {
									top: 80%;
									transform: translateY(-50%);
								}
							}
						}

						&.open {
							.hamburger {
								perspective: 50px;
								transform-style: preserve-3d;
								span {
									&.a,
									&.d {
										transform: translateY(-50%) scale(0);
									}
									&.b {
										transform: rotate(-45deg) translateY(-50%);
									}
									&.c {
										top: calc(50% - 1px);
										transform: rotate(45deg) translateY(50%);
									}
								}
							}
						}
					}
					&.desktop-menu-toggle {
						display: none;
					}
					&.search {
						display: none;
					}
				}
			}
		}
	}

	.label-fade-enter-active,
	.label-fade-leave-active {
		transition: opacity 350ms;
	}

	.label-fade-enter,
	.label-fade-leave-to {
		opacity: 0;
	}

	.mess-modal.full-menu-modal {
		height: calc(100% - var(--header-height));
		top: var(--header-height);
		backdrop-filter: 0;
		background: var(--gray-100);

		.body {
			background: transparent;
			width: 100%;
			height: 100%;

			background: var(--gray-100);

			button.close {
				display: none;
			}
		}
	}

	.mess-modal.search-modal {
		.body {
			width: min(1000px, 80%);

			.close {
				background-color: transparent;
				top: 1rem;
				right: 2rem;
			}
		}
		@media (max-width: $tablet) {
			.body {
				width: 100%;
				height: 100%;
				max-height: 100%;

				.close {
					top: 0;
					right: 0;
				}
			}
		}
	}
</style>
